@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

.schedule-payment-modal {
  overflow: visible;

  &__content-wrapper {
    padding: 0 $s2;

    @include breakpoint(medium) {
      padding: 0 $s4;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: $s2-5;
    @include breakpoint(medium) {
      flex-direction: row;
      gap: 12px;
    }
  }

  &__description {
    @include df-p3-medium;
    margin-bottom: $s2-5;

    @include breakpoint(medium) {
      @include df-p1-medium;
      margin-bottom: 10px;
    }
  }

  &__link {
    @include df-p1-medium;
    color: $red;
  }

  &__cancel-button {
    @include df-p1-medium;
    color: $red;
    text-decoration: underline;
    margin-right: $s3;
    cursor: pointer;
  }
  .modal {
    &-header {
      margin-bottom: $s2-5;

      @include breakpoint(medium) {
        margin-bottom: 10px;
      }
    }
  }

  &__month {
    margin-bottom: $s2-5;
    text-transform: capitalize;

    @include breakpoint(medium) {
      width: 40%;
      margin-bottom: 0;
    }
  }

  &__day-and-year {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(medium) {
      width: 58%;
    }
  }

  &__day {
    width: 40%;
  }

  &__year {
    width: 55%;
  }

  .select__control {
    height: 48px;
  }
}
