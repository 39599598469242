@import 'styles/_foundation';
@import 'styles/_variables';
@import 'styles/typography/_mixins';

$mobile-icon-size: 24px;
$desktop-icon-size: 32px;

.add-exceeded-item-to-cart-modal {
  &__title {
    @include df-h2;
    display: flex;
    padding: 0 $s2-5 $s1-5 0;
    margin-bottom: $s2-5;
    border-bottom: 1px solid $input-gray;
    color: $black;

    @include breakpoint(medium) {
      @include df-h3-bold;
      padding: 0 $s2-5 $s2-5 0;
    }

    img {
      margin-right: $s2;
      height: $mobile-icon-size;
      width: $mobile-icon-size;

      @include breakpoint(medium) {
        margin-right: $s2-5;
        height: $desktop-icon-size;
        width: $desktop-icon-size;
      }
    }
  }

  &__content {
    margin-bottom: $s5;
  }

  &__section {
    &-message {
      @include df-p1-medium;
      margin-bottom: $s2;
    }
  }

  &__exceeded-max-quantity-sku,
  &__short-supply-sku {
    margin-top: 10px;

    strong {
      @include df-p1-bold;
    }

    p {
      @include df-p2-medium;
      line-height: 24px;
      color: $black;
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -6px -10px;

    > button {
      flex: 1 0;
      white-space: nowrap;
      margin: 6px 10px;
    }
  }

  &__close-btn {
    @include breakpoint(medium) {
      max-width: 120px;

      @at-root button#{&} {
        margin-left: auto;
      }
    }
  }
}
